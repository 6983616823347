import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  getUserData, 
  login as apiLogin, 
  logout as apiLogout, 
  clearAuthDataHelper 
} from '../services/auth';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [role, setRole] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  const navigate = useNavigate();

  const clearAuthData = useCallback(() => {
    clearAuthDataHelper();
    setLoggedIn(false);
    setEmailVerified(false);
    setRole(null);
    setUser(null);
    setAuthChecked(true);
  }, []);

  const refreshAuthState = useCallback(async () => {
    if (!isLoading) setIsLoading(true);
    
    try {
      const response = await getUserData();
      const userData = response?.data;
      
      // Validate user data
      if (!userData?.user_id || !userData?.role) {
        throw new Error('Invalid user data');
      }

      // Update auth state with validated data
      setUser(userData);
      setLoggedIn(true);
      setEmailVerified(userData.email_verified || false);
      setRole(userData.role);
      setAuthChecked(true);

      // Only redirect if we're on login page and fully authenticated
      if (window.location.pathname === '/login' && userData.email_verified) {
        navigate('/dashboard', { replace: true });
      }
    } catch (err) {
      console.error('Auth check failed:', err);
      clearAuthData();
      if (window.location.pathname !== '/login') {
        navigate('/login', { replace: true });
      }
    } finally {
      setIsLoading(false);
    }
  }, [navigate, clearAuthData, isLoading]);

  const handleLogin = async (username, password) => {
    if (isLoading) return;
    
    try {
      setIsLoading(true);
      clearAuthData();
      
      await apiLogin(username, password);
      
      // Give cookies time to be set
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // Fetch user data after successful login
      await refreshAuthState();
    } catch (err) {
      console.error('Login failed:', err);
      clearAuthData();
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await apiLogout();
      clearAuthData();
      navigate('/login', { replace: true });
    } catch (err) {
      console.error('Logout failed:', err);
      clearAuthData();
      navigate('/login', { replace: true });
    }
  };

  // Initial auth check
  useEffect(() => {
    let mounted = true;

    const checkInitialAuth = async () => {
      if (!authChecked && mounted) {
        try {
          await refreshAuthState();
        } catch (err) {
          console.error('Initial auth check failed:', err);
          clearAuthData();
        }
      }
    };

    checkInitialAuth();

    return () => {
      mounted = false;
    };
  }, [refreshAuthState, authChecked, clearAuthData]);

  // Periodic check only when logged in
  useEffect(() => {
    if (!loggedIn) return;
    const checkInterval = setInterval(refreshAuthState, 5 * 60 * 1000);
    return () => clearInterval(checkInterval);
  }, [loggedIn, refreshAuthState]);

  const value = {
    loggedIn,
    emailVerified,
    role,
    user,
    isLoading,
    login: handleLogin,
    logout: handleLogout,
    refreshAuthState
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);