import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 
  (process.env.NODE_ENV === 'production' ? 'https://api.crypex.net' : 'http://127.0.0.1:8080');

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

export const clearAuthDataHelper = () => {
  const domains = ['', '.crypex.net', 'api.crypex.net', 'localhost', '127.0.0.1'];
  const paths = ['/', '/api', '/auth'];
  const cookieNames = [
    'access_token',
    'refresh_token',
    'session',
    'user_session',
    'connect.sid'
  ];
  
  domains.forEach(domain => {
    paths.forEach(path => {
      cookieNames.forEach(name => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}${domain ? `; domain=${domain}` : ''}`;
      });
    });
  });
  
  localStorage.clear();
  sessionStorage.clear();
};

export const login = async (username, password) => {
  try {
    // First try to logout to ensure clean state
    try {
      await logout();
    } catch (logoutError) {
      // Continue with login attempt even if logout fails
    }

    clearAuthDataHelper();
    const response = await api.post('/auth/login', { 
      username: username.toLowerCase().trim(), 
      password 
    });
    
    if (!response?.data) {
      throw new Error('Invalid login response');
    }

    // Wait for session to be established
    await new Promise(resolve => setTimeout(resolve, 100));
    
    return response;
  } catch (error) {
    // If we get a 403 and it's due to already being logged in, try to logout and retry
    if (error.response?.status === 403) {
      try {
        await logout();
        // Retry login after logout
        const retryResponse = await api.post('/auth/login', { 
          username: username.toLowerCase().trim(), 
          password 
        });
        return retryResponse;
      } catch (retryError) {
        clearAuthDataHelper();
        throw retryError;
      }
    }
    clearAuthDataHelper();
    throw error;
  }
};

export const logout = async () => {
  try {
    await api.post('/auth/logout');
    clearAuthDataHelper();
  } catch (error) {
    clearAuthDataHelper();
    throw error;
  }
};

export const getUserData = async () => {
  try {
    const response = await api.get('/auth/me');
    
    let userData;
    if (typeof response.data === 'string') {
      const sanitizedData = response.data.replace(/:\s*Infinity/g, ': 999999999');
      try {
        userData = JSON.parse(sanitizedData);
      } catch (parseError) {
        throw new Error('Invalid JSON response format');
      }
    } else {
      userData = response.data;
    }

    if (!userData || typeof userData !== 'object') {
      clearAuthDataHelper();
      throw new Error('Invalid user data format');
    }

    if (!userData.user_id) {
      clearAuthDataHelper();
      throw new Error('Invalid user data - Missing user_id');
    }

    // Process role_config to handle Infinity
    const roleConfig = userData.role_config || {};
    if (roleConfig.requestLimit === 'Infinity' || roleConfig.requestLimit === Infinity) {
      roleConfig.requestLimit = Number.MAX_SAFE_INTEGER;
    }

    return {
      data: {
        user_id: userData.user_id,
        role: userData.role || 'User',
        email_verified: Boolean(userData.email_verified),
        role_config: {
          features: roleConfig.features || { basicAnalysis: true },
          requestLimit: roleConfig.requestLimit || 10
        },
        username: userData.username,
        email: userData.email,
        request_count: userData.request_count || 0
      }
    };
  } catch (error) {
    clearAuthDataHelper();
    throw error;
  }
};

export const isAuthenticated = async () => {
  try {
    const response = await getUserData();
    return response.status === 200;
  } catch (err) {
    clearAuthDataHelper();
    return false;
  }
};

export const refreshTokenSilently = async () => {
  try {
    const response = await api.post('/auth/refresh', {}, { 
      withCredentials: true,
      skipAuthRefresh: true // Skip interceptor for this call
    });
    return response.status === 200;
  } catch (error) {
    console.error('Silent refresh failed:', error);
    return false;
  }
};

// Update the axios interceptor
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    
    // Prevent infinite loop and skip refresh token requests
    if (error.response?.status === 401 && !originalRequest._retry && !originalRequest.skipAuthRefresh) {
      originalRequest._retry = true;
      
      const refreshed = await refreshTokenSilently();
      if (refreshed) {
        return api(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

async function register(userData) {
  return await api.post('/auth/register', userData);
}

async function forgotPassword(email) {
  return await api.post('/auth/forgot-password', { email });
}

async function resetPassword(token, new_password) {
  return await api.post('/auth/reset-password', { token, new_password });
}

async function resendOtp(email) {
  return await api.post('/auth/resend-otp', { email });
}

async function verifyOtp(email, otp) {
  return await api.post('/auth/verify-otp', { email, otp });
}

async function getPublicRegistrationStatus() {
  return await api.get('/auth/registration-status');
}

// Export all functions
export {
  register,
  forgotPassword,
  resetPassword,
  resendOtp,
  getPublicRegistrationStatus,
  verifyOtp
};
